import { isMobile } from '@/utils'
export default {
  address: isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.4133154679057!2d121.54507481500598!3d25.02004388397876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aa2ff4247133%3A0x798f754d4f0ebbca!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A5Z-66ZqG6Lev5LiJ5q61MjAtMeiZnw!5e0!3m2!1szh-TW!2stw!4v1582457601443!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/3ZwyNvyAcLRSsaEK9',
  phone: '02-2738-6776',
  fbLink:
    'https://www.facebook.com/dunnanhowhow/',
  fbMessage: 'https://m.me/dunnanhowhow/',
  caseName: '敦南好好',
  houseInfos: [
    ['建設公司', '安家道生股份有限公司'],
    ['建築設計', '鄭誌明建築師事務所'],
    ['企劃銷售', '海沃創意行銷'],
    ['銷售坪數', '20坪-24坪 (二房)'],
    ['樓層高度', '3.6m'],
    ['戶數規劃', '27戶'],
    ['規劃車位', '16個'],
    ['建照執照', '(107)建字第0159號'],
    ['公設比例', '36.14%'],
    ['基地坪數', '152.46坪'],
    ['樓層規劃', '10F/B2'],

    ['建材說明',
      '德國Hansgrohe衛浴五金<br />美國KOHLER衛浴設備<br />義大利INDESIT內嵌式洗脫烘<br />林內系列廚具<br />五合一暖風機<br />美國YALE三合一電子鎖<br />WAFERLOCK對講機<br />日本不二太天氣密窗<br />PANASONIC冷氣室外機'],
  ],

  gtmCode: ['NNX6PHZ'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  },

  address2: isMobile ? '基地位置：<br />台北市大安區樂業街72巷16號旁' : '基地位置：台北市大安區樂業街72巷16號旁',
  googleLink2: 'https://goo.gl/maps/pE6BkLq6g5RNUGUV7',
  googleSrc2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.379048139361!2d121.54759841500596!3d25.021207383978254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aa3037eff0b1%3A0x46d0e1fdab4a533e!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A5qiC5qWt6KGXNzLlt7cxNuiZnw!5e0!3m2!1szh-TW!2stw!4v1582565071197!5m2!1szh-TW!2stw'
}
